import React from 'react';
import { Grid, TextField, IconButton, Button } from '@mui/material';
import PaginatedEntityDropdown from '../styled/CommonComponents/PaginatedEntityDropdown';
import DeleteIcon from '@mui/icons-material/Delete';

export default function UserEmailOverrides({ userToEmailOverrides, setUserToEmailOverrides, validationObj }) {

    const handleAddUser = () => {
        setUserToEmailOverrides([...userToEmailOverrides, { user: '', officialEmail: '' }]);
    };

    const handleRemoveUser = (index) => {
        const updatedOverrides = [...userToEmailOverrides];
        updatedOverrides.splice(index, 1);
        setUserToEmailOverrides(updatedOverrides);
    };

    const handleEmailChange = (index, email) => {
        const updatedOverrides = [...userToEmailOverrides];
        updatedOverrides[index].officialEmail = email;
        setUserToEmailOverrides(updatedOverrides);
    };

    return (
        <>
            {userToEmailOverrides.map((override, index) => (
                <Grid container spacing={1} key={index} alignItems="center">
                    <Grid item xs={5}>
                        <PaginatedEntityDropdown
                            value={override.user ? {
                                value: override.user,
                                label: override.displayName || '',
                                data: override.userData || {}
                            } : null}
                            onChange={(selectedValue) => {
                                const updatedOverrides = [...userToEmailOverrides];
                                updatedOverrides[index] = {
                                    ...updatedOverrides[index],
                                    user: selectedValue?.value || '',
                                    displayName: selectedValue?.label || '',
                                    userData: selectedValue?.data || {}
                                };
                                setUserToEmailOverrides(updatedOverrides);
                            }}
                            isMulti={false}
                            palCreate={false}
                            noFilter={true}
                            financialRelationType={"Admin"}
                            givenFilterOption={[
                                {
                                    option: "Network",
                                    types: ["User"]
                                }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            placeholder="Official Email"
                            size="small"
                            fullWidth
                            value={override.officialEmail || ''}
                            onChange={(e) => handleEmailChange(index, e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={() => handleRemoveUser(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            <Button
                variant="outlined"
                size="small"
                onClick={handleAddUser}
                style={{ marginTop: '10px' }}
            >
                Add User
            </Button>
            <p style={{ color: 'red', fontSize: '12px' }}>{validationObj?.userToEmailOverrides}</p>
        </>
    );
}
