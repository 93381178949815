import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import Button from "../styled/generic/Button";
import TableContainer from "../styled/generic/TableContainer";
import Api from "../../helpers/Api";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Schedule from "./payment.schedule";
import ScheduleEdit from "./payment.schedule.edit";

const Schedules = ({ libraryId }) => {
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [mode, setMode] = useState(null); // 'view' or 'edit'
  const { profileId } = useParams();
  const dispatch = useDispatch();

  const createSchedule = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/listing/booking/schedule/create", {
        library: libraryId,
      });
      if (data) {
        setSchedules([...schedules, data]);
        // Open the new schedule in edit mode
        setSelectedSchedule(data);
        setMode('edit');
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Schedule created successfully",
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  // Function to handle schedule selection
  const handleScheduleSelect = (schedule, viewMode = 'view') => {
    setSelectedSchedule(schedule);
    setMode(viewMode);
  };

  // Function to handle back button
  const handleBack = () => {
    setSelectedSchedule(null);
    setMode(null);
  };

  const handleViewBack = () =>{
    setMode('view');
  }



  const getSchedules = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/listing/booking/schedule/get", {
        libraryId: libraryId,
      });
      if (data) {
        setSchedules(data);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSchedules();
  }, []);

  // If a schedule is selected, show the appropriate component based on mode
  if (selectedSchedule) {
    if (mode === 'edit') {
      return (
        <ScheduleEdit 
          libraryId={libraryId}
          scheduleId={selectedSchedule._id}
          onBack={handleViewBack}
        />
      );
    }
    return (
      <Schedule 
        libraryId={libraryId}
        scheduleId={selectedSchedule._id}
        mode={mode}
        onBack={handleBack}
        onEditBack = {handleViewBack}
        onModeChange={setMode}
      />
    );
  }

  return (
    <Box>
      <BoxSpaceBetween>
        <Typography variant="h5">Schedules</Typography>
        <Button variant="outlined" color="primary" onClick={createSchedule}>
          Add Schedule
        </Button>
      </BoxSpaceBetween>
      <TableContainer
        columns={["Title", "Project", "Block", "Actions"]}
        data={schedules}
        loading={loading}
      >
        {schedules?.map((schedule) => (
          <tr key={schedule._id}>
            <td>{schedule?.title || "Untitled"}</td>
            <td>{schedule?.project?.displayName || "N/A"}</td>
            <td>{schedule?.projectBlock?.name || "N/A"}</td>
            <td>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button 
                  size="small" 
                  variant="outlined" 
                  onClick={() => handleScheduleSelect(schedule, 'view')}
                >
                  View
                </Button>
                <Button 
                  size="small" 
                  variant="outlined" 
                  onClick={() => handleScheduleSelect(schedule, 'edit')}
                >
                  Edit
                </Button>
              </Box>
            </td>
          </tr>
        ))}
      </TableContainer>
    </Box>
  );
};

export default Schedules;
