import React, { useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Close } from "@material-ui/icons";
import { useSelector } from "react-redux";
import DatePicker from "../styled/generic/DatePicker";
import dayjs from "dayjs";
import ExportPDF from "../styled/generic/ExportPDF";

const DailyReportDrawer = ({ open, onClose, parentModelName, parent }) => {
  const { teamDictionary } = useSelector((state) => state.team);
  const [dateRange, setDateRange] = useState({
    startDate: dayjs(),
    endDate: dayjs(),
  });
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // Get projects if parentModelName is Organization
  const projects = React.useMemo(() => {
    if (parentModelName !== "Organization") return [];
    
    return Object.values(teamDictionary)
      .filter(team => team?.parentModelName === "Project" && team?.parent)
      .map(team => ({
        id: team.parent._id,
        name: team.parent.displayName
      }));
  }, [teamDictionary, parentModelName]);

  // Create the API body based on selected options
  const apiBody = React.useMemo(() => ({
    startDate: dateRange.startDate.format("YYYY-MM-DD"),
    endDate: dateRange.endDate.format("YYYY-MM-DD"),
    projectIds: parentModelName === "Organization" 
      ? selectedProjects 
      : [parent?._id]
  }), [dateRange, selectedProjects, parentModelName, parent]);

  // Reference to the ExportPDF component
  const exportPdfRef = React.useRef();

  const handleGenerateReport = async () => {
    if (exportPdfRef.current) {
      setIsLoading(true);
      try {
        await exportPdfRef.current.exportPDF();
        onClose();
      } catch (error) {
        console.error('PDF generation failed:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Box sx={{ width: 400, p: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h6">Generate Daily Report</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        <Box display="flex" flexDirection="column" gap={3}>
          <Box>
            <Typography variant="subtitle2" mb={1}>Date Range</Typography>
            <Box display="flex" gap={2}>
              <DatePicker
                label="Start Date"
                value={dateRange.startDate}
                onChange={(date) => setDateRange(prev => ({ ...prev, startDate: date }))}
              />
              <DatePicker
                label="End Date"
                value={dateRange.endDate}
                onChange={(date) => setDateRange(prev => ({ ...prev, endDate: date }))}
              />
            </Box>
          </Box>

          {parentModelName === "Organization" && (
            <FormControl fullWidth>
              <InputLabel>Select Projects</InputLabel>
              <Select
                multiple
                value={selectedProjects}
                onChange={(e) => setSelectedProjects(e.target.value)}
                renderValue={(selected) => 
                  selected
                    .map(id => projects.find(p => p.id === id)?.name)
                    .join(", ")
                }
              >
                {projects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <ExportPDF
            ref={exportPdfRef}
            apiUrl="/wbs/dailyreport/getPdf"
            fileName={`daily-report-${dateRange.startDate.format("YYYY-MM-DD")}`}
            apiBody={apiBody}
            printButton={
              <Button
                variant="contained"
                disabled={
                  (parentModelName === "Organization" && !selectedProjects.length) ||
                  isLoading
                }
                onClick={handleGenerateReport}
                fullWidth
              >
                {isLoading ? (
                  <>
                    <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
                    Generating Report...
                  </>
                ) : (
                  'Generate Report'
                )}
              </Button>
            }
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default DailyReportDrawer;
