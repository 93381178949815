import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDrawer from '../styled/CommonComponents/NormalDrawer';
import NotifyAlert from '../styled/CommonComponents/NotifyAlert';
import {
    Autocomplete, Button, Grid, TextField,
    Checkbox, FormControlLabel, FormGroup
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import ProjectPicker from '../styled/generic/ProjectPicker';
import PaginatedEntityDropdown from '../styled/CommonComponents/PaginatedEntityDropdown';
import * as yup from "yup";
import "../../App.css";
import {
    createReportSettingApiCall,
    updateReportSettingApiCall,
    getReportSettingsApiCall
} from './Api.call';
import UserEmailOverrides from './UserEmailOverrides';
import TimezoneSelect from 'react-timezone-select';

// Validation schema using Yup
const reportSettingSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    modules: yup.array()
        .of(yup.string().oneOf(["Task Progress", "Inventory"]))
        .min(1, 'At least one module is required'),
    userToEmailOverrides: yup.array().of(
        yup.object().shape({
            user: yup.string().required('User is required'),
            officialEmail: yup.string().email('Invalid email format').required('Email is required')
        })
    ).min(1, 'At least one recipient is required'),
    projectMaps: yup.array().of(
        yup.object().shape({
            project: yup.string().required('Project is required')
        })
    ).min(1, 'At least one project is required'),
    emailSchedule: yup.object().shape({
        enabled: yup.boolean().required(),
        frequency: yup.string().oneOf(['daily', 'weekly', 'monthly']).required('Frequency is required'),
        time: yup.string().required('Time is required'),
        timeZone: yup.string().required('Time Zone is required'),
        excludeDays: yup.array().of(
            yup.number().oneOf([0, 1, 2, 3, 4, 5, 6])
        ),
        dayOfWeek: yup.number().when('frequency', {
            is: 'weekly',
            then: yup.number().oneOf([0, 1, 2, 3, 4, 5, 6]).required('Day of week is required for weekly frequency')
        }),
        dayOfMonth: yup.number().when('frequency', {
            is: 'monthly',
            then: yup.number().min(1).max(31).required('Day of month is required for monthly frequency')
        }),
        // recipients: yup.array().of(yup.string()).min(1, 'At least one recipient is required')
    })
});

const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "15px",
        paddingBottom: "30px"
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    },
    label: {
        fontSize: "15px",
        fontWeight: "500",
        marginBottom: "3px",
        marginTop: "2px"
    },
    requiredSty: {
        color: "red"
    },
    validationSty: {
        color: "red",
        fontSize: "12px",
        marginTop: "2px"
    }
}));

export default function ReportSettingAddEdit({
    openDrawer, setOpenDrawer,
    editData = null,
    getAllReportSettings,
    setEditData, orgId
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth);

    const [loader, setLoader] = useState(false);
    const [alertObj, setAlertObj] = useState({});
    const [validationObj, setValidationObj] = useState({});

    const [timeZone, setTimeZone] = useState({ 
        label : '(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi',
        abbrev: 'IST' 
    }); // Update initial state to be an object
    // Form state variables
    const [name, setName] = useState("");
    const [modules, setModules] = useState(["Task Progress"]);
    const [userToEmailOverrides, setUserToEmailOverrides] = useState([]);
    const [projectMaps, setProjectMaps] = useState([]);
    const [emailScheduleEnabled, setEmailScheduleEnabled] = useState(false);
    const [frequency, setFrequency] = useState("daily");
    const [time, setTime] = useState(dayjs('09:00', 'HH:mm'));
    const [excludeDays, setExcludeDays] = useState([0, 6]); // Default exclude Sunday and Saturday
    const [dayOfWeek, setDayOfWeek] = useState(1); // For weekly reports, default to Monday
    const [dayOfMonth, setDayOfMonth] = useState(1); // For monthly reports
    const [recipients, setRecipients] = useState([]);

    console.log(editData,projectMaps,' editData,projectMaps', name)

    useEffect(() => {
        if (openDrawer) {
            if (editData?._id) {
                // Populate fields with existing data for editing
                setName(editData.name || "");
                setModules(editData.modules || ["Task Progress"]);
                setUserToEmailOverrides(editData.userToEmailOverrides.map(u => ({
                    user: u.user?._id,
                    officialEmail: u.officialEmail
                })));
                console.log(editData,' before setting')
                console.log(editData.projectMaps.map(pM => pM.project),' editData.projectMaps.map(pM => pM.project)')
             
                setProjectMaps(editData.projectMaps.map(pM => pM.project));
                setEmailScheduleEnabled(editData.emailSchedule.enabled);
                setFrequency(editData.emailSchedule.frequency);
                setTime(dayjs(editData.emailSchedule.time, 'HH:mm'));
                setExcludeDays(editData.emailSchedule.excludeDays || []);
                setDayOfWeek(editData.emailSchedule.dayOfWeek || 1);
                setDayOfMonth(editData.emailSchedule.dayOfMonth || 1);
                setRecipients(editData.emailSchedule.recipients || []);
                setTimeZone({ abbrev: editData.emailSchedule.timeZone }); // Wrap timezone in object
            } else {
                // Reset fields for new entry
                resetData();
            }
        } else {
            setEditData(null);
        }
    }, [editData, openDrawer]);

    const resetData = () => {
        setName("");
        setModules(["Task Progress"]);
        setUserToEmailOverrides([]);
        setProjectMaps([]);
        setEmailScheduleEnabled(false);
        setFrequency("daily");
        setTime(dayjs('09:00', 'HH:mm'));
        setExcludeDays([0, 6]);
        setDayOfWeek(1);
        setDayOfMonth(1);
        setRecipients([]);
        setValidationObj({});
        setAlertObj({});
    };

    const handleExcludeDayChange = (event) => {
        const { value } = event.target;
        setExcludeDays((prev) =>
            prev.includes(value)
                ? prev.filter((day) => day !== value)
                : [...prev, value]
        );
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const reportSettingData = {
            name,
            modules,
            org: orgId,
            userToEmailOverrides: userToEmailOverrides.map(obj => {
                return {
                    user: obj.user,
                    officialEmail: obj.officialEmail
                }
            }),
            projectMaps: projectMaps.map(project => {
                return { project: project?._id, projectProfile: project?.profile?._id }
            }),
            emailSchedule: {
                enabled: emailScheduleEnabled,
                frequency,
                time: time.format('HH:mm'),
                timeZone: timeZone.abbrev?.replace(/"/g, ''),
                excludeDays,
                dayOfWeek: frequency === 'weekly' ? dayOfWeek : undefined,
                dayOfMonth: frequency === 'monthly' ? dayOfMonth : undefined,
                recipients
            },
            user: user?._id
        };

        try {
            await reportSettingSchema.validate(reportSettingData, { abortEarly: false });
            setLoader(true);

            if (editData && editData?._id) {
                // Update existing ReportSetting
                await updateReportSettingApiCall({ _id: editData._id, updatedData: reportSettingData });
                getAllReportSettings();
                setAlertObj({
                    show: true,
                    message: "Report Setting successfully updated!",
                    status: "success"
                });
                setOpenDrawer(false);
                resetData();
            } else {
                // Create new ReportSetting
                await createReportSettingApiCall(reportSettingData);
                getAllReportSettings();
                setAlertObj({
                    show: true,
                    message: "Report Setting successfully created!",
                    status: "success"
                });
                setOpenDrawer(false);  // Ensure drawer closes
                resetData();
            }
        } catch (err) {
            console.log(err);
            if (err.inner) {
                // Validation error
                let obj = {};
                err.inner.forEach((err) => {
                    obj[err.path] = err.message;
                });
                setValidationObj(obj);
            }
            setAlertObj({
                show: true,
                message: err.inner ? "Please enter valid data in the form." : "Error saving Report Setting.",
                status: "error",
            });
        } finally {
            setLoader(false);
        }
    };

    return (
        <>
            <NormalDrawer
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                anchor={"right"}
                width={"800px"}
                title={editData?._id ? "Edit Report Setting" : "Add Report Setting"}
                content={
                    <>
                        {openDrawer && (
                            <form className={classes.mainDialogCont} onSubmit={onSubmit}>
                                <div className={classes.bodyCont}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Grid container spacing={1}>
                                            {/* Name Field */}
                                            <Grid item xs={12}>
                                                <p className={classes.label}>Name<span className={classes.requiredSty}>*</span></p>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                                <p className={classes.validationSty}>{validationObj?.name}</p>
                                            </Grid>

                                            {/* Modules Field */}
                                            <Grid item xs={12}>
                                                <p className={classes.label}>Modules<span className={classes.requiredSty}>*</span></p>
                                                <Autocomplete
                                                    multiple
                                                    options={["Task Progress", "Inventory"]}
                                                    value={modules}
                                                    onChange={(evt, newValue) => setModules(newValue)}
                                                    getOptionLabel={(option) => option || ""}
                                                    fullWidth
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            {...params}
                                                            size="small"
                                                        />
                                                    )}
                                                />
                                                <p className={classes.validationSty}>{validationObj?.modules}</p>
                                            </Grid>

                                            {/* User Email Overrides */}
                                            <Grid item xs={12}>
                                                <p className={classes.label}>User Email Overrides<span className={classes.requiredSty}>*</span></p>
                                                <UserEmailOverrides
                                                    userToEmailOverrides={userToEmailOverrides}
                                                    setUserToEmailOverrides={setUserToEmailOverrides}
                                                    validationObj={validationObj}
                                                />
                                            </Grid>

                                            {/* Project Maps */}
                                            <Grid item xs={12}>
                                                <p className={classes.label}>Project(s)<span className={classes.requiredSty}>*</span></p>
                                                <ProjectPicker
                                                    selectedProject={projectMaps||[]}
                                                    setSelectedProject={setProjectMaps}
                                                    isMulti={true}
                                                    fullWidth={true}
                                                />
                                                <p className={classes.validationSty}>{validationObj?.projectMaps}</p>
                                            </Grid>

                                            {/* Email Schedule */}
                                            <Grid item xs={12}>
                                                <p className={classes.label}>Email Schedule</p>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={emailScheduleEnabled}
                                                            onChange={(e) => setEmailScheduleEnabled(e.target.checked)}
                                                        />
                                                    }
                                                    label="Enable Email Schedule"
                                                />
                                            </Grid>

                                            {/* Email Schedule Details */}
                                            {emailScheduleEnabled && (
                                                <>
                                                    {/* Frequency */}
                                                    <Grid item xs={12}>
                                                        <p className={classes.label}>Frequency<span className={classes.requiredSty}>*</span></p>
                                                        <Autocomplete
                                                            options={["daily", "weekly", "monthly"]}
                                                            value={frequency}
                                                            onChange={(evt, newValue) => setFrequency(newValue)}
                                                            getOptionLabel={(option) => option || ""}
                                                            fullWidth
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    fullWidth
                                                                    {...params}
                                                                    size="small"
                                                                />
                                                            )}
                                                        />
                                                        <p className={classes.validationSty}>{validationObj?.emailSchedule?.frequency}</p>
                                                    </Grid>

                                                    {/* Time */}
                                                    <Grid item xs={12}>
                                                        <p className={classes.label}>Time<span className={classes.requiredSty}>*</span></p>
                                                        <TimePicker
                                                            value={time}
                                                            onChange={(newValue) => setTime(newValue)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    size="small"
                                                                    fullWidth
                                                                />
                                                            )}
                                                        />
                                                        <p className={classes.validationSty}>{validationObj?.emailSchedule?.time}</p>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <p className={classes.label}>Time Zone<span className={classes.requiredSty}>*</span></p>
                                                        <TimezoneSelect
                                                            value={timeZone}
                                                            onChange={setTimeZone}
                                                        />
                                                        <p className={classes.validationSty}>{validationObj?.emailSchedule?.timeZone}</p>
                                                    </Grid>

      {/* Exclude Days for Daily Frequency */}
      {frequency === 'daily' && (
                                                        <Grid item xs={12}>
                                                            <p className={classes.label}>Exclude Days</p>
                                                            <FormGroup row>
                                                                {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map((day, index) => (
                                                                    <FormControlLabel
                                                                        key={index}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={excludeDays.includes(index)}
                                                                                onChange={(e) => {
                                                                                    const dayValue = index;
                                                                                    setExcludeDays(prev =>
                                                                                        prev.includes(dayValue)
                                                                                            ? prev.filter(d => d !== dayValue)
                                                                                            : [...prev, dayValue]
                                                                                    );
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={day}
                                                                    />
                                                                ))}
                                                            </FormGroup>
                                                        </Grid>
                                                    )}

                                                    {/* Day of Week for Weekly Frequency */}
                                                    {frequency === 'weekly' && (
                                                        <Grid item xs={12}>
                                                            <p className={classes.label}>Day of Week<span className={classes.requiredSty}>*</span></p>
                                                            <Autocomplete
                                                                options={[
                                                                    { label: "Sunday", value: 0 },
                                                                    { label: "Monday", value: 1 },
                                                                    { label: "Tuesday", value: 2 },
                                                                    { label: "Wednesday", value: 3 },
                                                                    { label: "Thursday", value: 4 },
                                                                    { label: "Friday", value: 5 },
                                                                    { label: "Saturday", value: 6 },
                                                                ]}
                                                                value={{ label: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][dayOfWeek], value: dayOfWeek }}
                                                                onChange={(evt, newValue) => setDayOfWeek(newValue.value)}
                                                                getOptionLabel={(option) => option.label || ""}
                                                                fullWidth
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        fullWidth
                                                                        {...params}
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                            <p className={classes.validationSty}>{validationObj?.emailSchedule?.dayOfWeek}</p>
                                                        </Grid>
                                                    )}

                                                    {/* Day of Month for Monthly Frequency */}
                                                    {frequency === 'monthly' && (
                                                        <Grid item xs={12}>
                                                            <p className={classes.label}>Day of Month<span className={classes.requiredSty}>*</span></p>
                                                            <TextField
                                                                type="number"
                                                                size="small"
                                                                fullWidth
                                                                value={dayOfMonth}
                                                                onChange={(e) => setDayOfMonth(e.target.value)}
                                                            />
                                                            <p className={classes.validationSty}>{validationObj?.emailSchedule?.dayOfMonth}</p>
                                                        </Grid>
                                                    )}


                                                </>
                                            )}
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                                <div style={{ width: "100%", height: "5px" }}>
                                    {loader && (<LinearProgress />)}
                                </div>
                                <div className={classes.bottomAct}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        disabled={loader}
                                        onClick={onSubmit}
                                    >
                                        Save & Close
                                    </Button>
                                </div>
                            </form>
                        )}
                    </>
                }
            />
            <NotifyAlert
                alertObj={alertObj}
                setAlertObj={setAlertObj}
            />
        </>
    );
}
