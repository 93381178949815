import React, { useEffect, useState } from "react";
import StandardContainerWithCustomAppBar from "../styled/generic/StandardContainerWithCustomAppBar";
import { Add, Settings, DateRange, Payment, Description, Assessment, Receipt } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
  Menu,
  MenuItem,
  Button,
  Select,
  FormControl,
  InputLabel,
  Drawer,
} from "@mui/material";
import Image from "../styled/generic/Image";
import PopupMenuList from "../styled/generic/PopupMenuList";
import MoreButton from "../styled/generic/MoreButton";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import DateText from "../styled/generic/DateText";
import DatePicker from "../styled/generic/DatePicker";
import dayjs from "dayjs";
import Api from "../../helpers/Api";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getProfileData } from "../team/procurements/api.call";
import { getReportSettingsApiCall } from './Api.call'
import DailyReportDrawer from "./DailyReportDrawer";
import ReportSettingAddEdit from './ReportSettingAddEdit';
import ReportSettingsDrawer from './ReportSettingsDrawer';
// Import the schedules component
import Schedules from "./payment.schedules";
import Schedule from "./payment.schedule"; 


const SiteReport = () => {
  const [siteVisitReports, setSiteVisitReports] = useState([]);
  const [customerReports, setCustomerReports] = useState([]);
  const [filterDate, setFilterDate] = useState(() => {
    const endDate = dayjs();
    const startDate = endDate.subtract(30, 'day');
    return {
      startDate: startDate,
      endDate: endDate
    };
  });
  const [filterType, setFilterType] = useState("All");
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const { profileId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [dailyReportDrawerOpen, setDailyReportDrawerOpen] = useState(false);
  const [reportSettingDrawerOpen, setReportSettingDrawerOpen] = useState(false);
  const [reportSettings, setReportSettings] = useState([]);
  const [editReportSetting, setEditReportSetting] = useState(null);
  const [reportSettingsDrawerOpen, setReportSettingsDrawerOpen] = useState(false);
  // New states for toggling views
  const [viewMode, setViewMode] = useState("siteReports"); 
  // "siteReports" or "paymentSchedules"
  
  const dispatch = useDispatch();
  console.log(profileData);
  const parent = profileData?.parent;
  const parentModelName = profileData?.parentModelName;

  console.log(parent,' is the parent')
  useEffect(() => {
    if (profileId) {
      getProfileData({ profileId })
        .then((data) => {
          setProfileData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profileId]);

  const processWBSProgressData = (taskReport, startDate, endDate) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const dateRange = [];
  
    for (let d = start; d.isBefore(end) || d.isSame(end); d = d.add(1, 'day')) {
      dateRange.push(d.format('YYYY-MM-DD'));
    }
  
    const groupedData = taskReport.reduce((acc, item) => {
      if (item && item.progressUpdate && item.progressUpdate.createdAt) {
        const date = dayjs(item.progressUpdate.createdAt).format('YYYY-MM-DD');
        if (!acc[date]) {
          acc[date] = 0;
        }
        acc[date] += 1;
      }
      return acc;
    }, {});
  
    const chartData = dateRange.map(date => ({
      date,
      tasksUpdated: groupedData[date] || 0
    }));
  
    const totalUpdates = chartData.reduce((sum, day) => sum + day.tasksUpdated, 0);
  
    return { chartData, totalUpdates };
  };
  const createSiteReport = async (type) => {
    try {

      var orgId = parentModelName == 'Project' ? 
        (parent?.ownerProfile?.parentModelName == 'Organization'?
          parent?.ownerProfile?._id:null):parent?._id

      let siteReportObj = parentModelName == 'Project' ? {
        user: user?._id,
        type: type,
        project: parent?._id, 
        organization:orgId  
      }:{
        user: user?._id,
        type: type,
        organization: parent?._id, 
      }
      const { data } = await Api.post("/site-report/create", siteReportObj);
      if (data) {
        if (type === "Site Visit") {
          history.push(`/site-report/edit/${data._id}`);
        } else if (type === "Customer Report") {
          history.push(`/customer-report/edit/${data._id}`);
        }
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create site report",
        },
      });
    }
  };

  const handleMenuItemClick = (reportType) => {
    setAnchorEl(null);
    if (reportType === 'Quality and Safety Report') {
      createSiteReport("Site Visit");
    } else if (reportType === 'Customer Report') {
      createSiteReport("Customer Report");
    }
  };

  const handleFilterDate = async (pageNum, skipLoading = false) => {
    try {
      if (!skipLoading) setLoading(true);
  
 

      const fetchReports = async (type) => {

        var queryObject = parentModelName == 'Project'?{
          profileId: profileId,
          project:parent?._id,
          type: type,
          startDate: filterDate.startDate.format("YYYY-MM-DD"),
          endDate: filterDate.endDate.format("YYYY-MM-DD")
        }:{
          profileId: profileId,
          organization:parent?._id,
          type: type,
          startDate: filterDate.startDate.format("YYYY-MM-DD"),
          endDate: filterDate.endDate.format("YYYY-MM-DD")
        }

        const { data } = await Api.post("/site-report/get", queryObject);

        const filteredData = data.filter(report => {
          const createdAt = dayjs(report.createdAt);
          return createdAt.isAfter(filterDate.startDate.startOf('day')) && 
                 createdAt.isBefore(filterDate.endDate.endOf('day'));
        });
  
        return filteredData;
      };
  
      const siteVisitData = await fetchReports("Site Visit");
      const customerData = await fetchReports("Customer Report");
  
      setSiteVisitReports(siteVisitData || []);
      setCustomerReports(customerData || []);
  
      const totalCount = siteVisitData.length + customerData.length;
      setCount(Math.ceil(totalCount / pageSize));
  
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFilterDate();
  }, [profileId]);

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };

  const deleteSiteReport = async (reportId, type) => {
    try {
      const { data } = await Api.post("/site-report/delete", {
        reportId: reportId,
      });
      if (data) {
        if (type === "Site Visit") {
          setSiteVisitReports(prevReports => prevReports.filter((item) => item._id !== reportId));
        } else if (type === "Customer Report") {
          setCustomerReports(prevReports => prevReports.filter((item) => item._id !== reportId));
        }
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Site report deleted successfully",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to delete site report",
        },
      });
    }
  };

  const renderReportContent = (item) => {
    if (item.type === "Customer Report") {
      const startDate = item.startDate ? dayjs(item.startDate) : dayjs().subtract(1, 'month');
      const endDate = item.endDate ? dayjs(item.endDate) : dayjs();
  
      const { chartData, totalUpdates } = processWBSProgressData(item.TaskReport || [], startDate, endDate);
      console.log("The items are:", item);
      // Calculate the appropriate bar size and gap
      const barSize = Math.max(40, 200 / chartData.length);
      const barGap = Math.max(2, 20 / chartData.length);
  
      return (
        <>
          <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
            {startDate.format('MMM DD, YYYY')} - {endDate.format('MMM DD, YYYY')}
          </Typography>
          <Box sx={{ my: 1, height: 200 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={chartData}
                margin={{
                  top: 10,
                  right: 10,
                  left: 0,
                  bottom: 20,
                }}
                barSize={barSize}
                barGap={barGap}
              >
                <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} stroke="#f0f0f0" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={(value) => dayjs(value).format('DD MMM')}
                  interval={Math.max(Math.floor(chartData.length / 7) - 1, 0)}
                  axisLine={false}
                  tickLine={false}
                  angle={-45}
                  textAnchor="end"
                  height={50}
                  dy={20}
                />
                <YAxis 
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={(value) => value > 0 ? value : ''}
                  width={30}
                />
                <Tooltip 
                  labelFormatter={(value) => dayjs(value).format('MMM DD, YYYY')}
                  formatter={(value) => [value, 'Tasks Updated']}
                  cursor={{fill: 'rgba(0, 0, 0, 0.05)'}}
                />
                <Bar 
                  dataKey="tasksUpdated" 
                  fill="#8884d8" 
                  name="Tasks Updated" 
                  radius={[4, 4, 0, 0]} 
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
          
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
            <Typography variant="body1">
              Tasks: {item.tasks?.length || 0}
            </Typography>
            <Typography variant="body1">
              Updates: {totalUpdates}
            </Typography>
          </Box>
        </>
      );
    } else {
      return (
        <>
          <DateText
            date={item?.createdAt}
            prefix="Created at: "
            variant="body2"
          />
          <Box sx={{ my: 1 }}>
            <Image
              width="100%"
              src={
                item?.observations?.[0]?.docs?.[0]?.files?.find(
                  (item) => item.type.includes("image")
                )?.url || ""
              }
              height={150}
              imageProps={{ borderRadius: "8px", cursor: "pointer" }}
              onClick={() =>
                history.push(`/site-report/view/${item._id}`)
              }
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600 }}
            gutterBottom
          >
            {item?.project?.displayName || "No Project"}
          </Typography>
          <DateText
            date={item?.visitDate}
            prefix="Visit date: "
            variant="body2"
          />
        </>
      );
    }
  };

  const getAllReportSettings = async () => {
    try {
      const data = await getReportSettingsApiCall({
        orgId : parent?._id
      });
      console.log(' getReportSettings ',data)
      setReportSettings(data);
    } catch (error) {
      console.error('Error fetching report settings:', error);
    }
  };

  useEffect(() => {
    if(parent?._id){
      getAllReportSettings();
    }
  }, [parent?._id]);

  return (
    <StandardContainerWithCustomAppBar
      appBarContent={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {viewMode === "siteReports" ? "Site Reports" : "Payment Schedules"}
          </Typography>
          <Box display="flex" gap={2}>
            {parentModelName === 'Organization' && (
              <Button
                variant="outlined"
                startIcon={<Settings />}
                onClick={() => setReportSettingsDrawerOpen(true)}
              >
                Report Settings
              </Button>
            )}
            <Button
              variant="outlined"
              startIcon={<DateRange />}
              onClick={() => setDailyReportDrawerOpen(true)}
            >
              Daily Report
            </Button>
            <Button
              variant="outlined"
              startIcon={viewMode === "siteReports" ? <Payment /> : <Description />}
              onClick={() => setViewMode(viewMode === "siteReports" ? "schedules" : "siteReports")}
            >
              {viewMode === "siteReports" ? "Payment Schedules" : "Site Reports"}
            </Button>
            {viewMode === "siteReports" && (
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                Create Report
              </Button>
            )}
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => handleMenuItemClick('Quality and Safety Report')}>
              <Assessment sx={{ mr: 1 }} /> Quality and Safety Report
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('Customer Report')}>
              <Receipt sx={{ mr: 1 }} /> Customer Report
            </MenuItem>
          </Menu>
        </Box>
      }
    >
      {viewMode === "siteReports" ? (
        <div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
              alignItems: "center",
              paddingBottom: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <span style={{ fontSize: "1.1rem" }}>Start date</span>
                <DatePicker
                  value={filterDate.startDate}
                  onChange={(date) => {
                    setFilterDate(prevState => ({
                      ...prevState,
                      startDate: date
                    }));
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <span style={{ fontSize: "1.1rem" }}>End date</span>
                <DatePicker
                  value={filterDate.endDate}
                  onChange={(date) => {
                    setFilterDate(prevState => ({
                      ...prevState,
                      endDate: date
                    }));
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <span style={{ fontSize: "1.1rem" }}>Report Type</span>
                <FormControl style={{ minWidth: 200 }}>
                  <Select
                    value={filterType}
                    onChange={handleFilterTypeChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Site Visit">Site Visit</MenuItem>
                    <MenuItem value="Customer Report">Customer Report</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <button
              onClick={() => handleFilterDate(1)}
              style={{
                width: "7rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "3rem",
                borderRadius: "0.5rem",
                color: "white",
                backgroundColor: "#1976d2",
              }}
            >
              {loading ? (
                <CircularProgress style={{ color: "white" }} size={24} />
              ) : (
                "Apply"
              )}
            </button>
          </div>

          {(siteVisitReports.length > 0 || customerReports.length > 0) ? (
            <Grid container spacing={2}>
              {[...customerReports, ...siteVisitReports]
                .filter(item => filterType === "All" || item.type === filterType)
                .map((item, index) => (
                  <Grid item xs={3} key={item._id}>
                    <Box
                      sx={{
                        borderRadius: "12px",
                        border: "1px solid #F3F3F3",
                        boxShadow: " 0px 0px 80px 0px rgba(171, 190, 209, 0.10)",
                        p: 2,
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mb: 1 }}
                      >
                        <Box display="flex" alignItems="center">
                          <Avatar
                            src={item?.user?.displayPicture?.url}
                            sx={{ width: 28, height: 28 }}
                          />
                          <Typography variant="h6" sx={{ ml: 1 }}>
                            {item.title || (item.type === "Customer Report" ? "Untitled" : "Untitled")}
                          </Typography>
                        </Box>
                        <Box>
                          <PopupMenuList
                            appearContent={<MoreButton />}
                            actions={[
                              {
                                title: "Edit",
                                action: () => {
                                  history.push(`/${item.type === "Customer Report" ? "customer-report" : "site-report"}/edit/${item._id}`);
                                },
                              },
                              {
                                title: "Delete",
                                action: () => deleteSiteReport(item._id, item.type),
                              },
                            ]}
                          />
                        </Box>
                      </Box>

                      {renderReportContent(item)}
                    </Box>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <div
              style={{
                width: "100%",
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {filterDate?.startDate && filterDate?.endDate && (
                <span style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                  No reports found between date range {filterDate.startDate.format("YYYY-MM-DD")} to{" "}
                  {filterDate.endDate.format("YYYY-MM-DD")}.{" "}
                </span>
              )}
            </div>
          )}

          {filterDate?.startDate && filterDate?.endDate && (siteVisitReports.length > 0 || customerReports.length > 0) && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "2rem",
              }}
            >
              <Pagination
                color="primary"
                count={count}
                page={pageNumber}
                onChange={(e, val) => {
                  setPageNumber(val);
                  handleFilterDate(val, true);
                }}
                size="large"
              />
            </div>
          )}
        </div>
      ) : (
        <Schedules 
          libraryId={parent?.wallet?.defaultLibrary}
          onScheduleClick={(schedule) => {
            history.push(`/payment-schedules/${schedule._id}?mode=view`);
          }}
        />
      )}
      <DailyReportDrawer 
        open={dailyReportDrawerOpen}
        onClose={() => setDailyReportDrawerOpen(false)}
        parentModelName={parentModelName}
        parent={parent}
      />
      
      <ReportSettingsDrawer
        open={reportSettingsDrawerOpen}
        onClose={() => setReportSettingsDrawerOpen(false)}
        onEdit={(editData) => {
          setEditReportSetting(editData);
          setReportSettingDrawerOpen(true);
        }}
        reportSettings={reportSettings}
        orgId={parent?._id}
        setReportSettings={setReportSettings}
      />

      <ReportSettingAddEdit
        openDrawer={reportSettingDrawerOpen}
        setOpenDrawer={(value) => {
          setReportSettingDrawerOpen(value);
          if (!value) {
            getAllReportSettings();
          }
        }}
        editData={editReportSetting}
        setEditData={setEditReportSetting}
        getAllReportSettings={getAllReportSettings}
        orgId={parent?._id}
      />
    </StandardContainerWithCustomAppBar>
  );
};

export default SiteReport;