const allStatusOptions = {
  "WBS": {
    "Not Started": ["In Progress", "Delayed", "Cancelled"],
    "In Progress": ["Completed",  "Delayed", "Awaiting Materials", "Cancelled"],
    "Completed": ["Quality Check", "Pending Approval"],
    "Pending Approval": ["Under Review", "Rejected"],
    "On Hold": ["In Progress", "Cancelled"],
    "Delayed": ["In Progress", "Cancelled"],
    "Cancelled": ["Reopened"],
    "Awaiting Materials": ["In Progress", "Delayed", "Cancelled"],
    "Quality Check": ["Under Review", "In Progress"],
    "Under Review": ["Approved", "Rejected"],
    "Approved": ["Closed", "Cancelled"],
    "Rejected": ["In Progress"],
    "Closed": ["Reopened"],
    "Reopened": ["In Progress"]
  },
  "Material Request": {
    Draft: ["Submitted"],
    Submitted: ["Under Review", "Rejected"],
    "Under Review": ["Approved", "Rejected"],
    Approved: ["Draft", "Rejected"],
    Rejected: ["Draft"],
  },
  "Bill Of Quantities": {
    Draft: ["Submitted"],
    Submitted: ["Evaluated", "Rejected"],
    Evaluated: ["Approved", "Rejected"],
    Approved: ["Draft", "Rejected"],
    Rejected: ["Draft"],
  },
  "Purchase Order": {
    Draft: ["Submitted"],
    Submitted: ["Approved", "Rejected"],
    Approved: ["Issued", "Rejected"],
    Issued: ["Acknowledged", "Rejected"],
    Acknowledged: ["Partially Paid", "Paid", "Rejected"],
    "Paid": ["Draft", "Rejected"],
    "Partially Paid": ["Draft", "Rejected"],
    Rejected: ["Draft"],
  },
  "Work Order": {
    Draft: ["Submitted"],
    Submitted: ["Approved", "Rejected"],
    Approved: ["Issued", "Rejected"],
    Issued: ["Acknowledged", "Rejected"],
    Acknowledged: ["Partially Paid", "Paid", "Rejected"],
    "Paid": ["Draft", "Rejected"],
    "Partially Paid": ["Draft", "Rejected"],
    Rejected: ["Draft"],
  },
  GRN: {
    Draft: ["Submitted"],
    Submitted: ["Approved", "Rejected"],
    Approved: ["Draft", "Rejected"],
    Rejected: ["Draft"],
  },
  Estimation: {
    Draft: ["Submitted"],
    Submitted: ["Approved", "Rejected"],
    Approved: ["Draft"],
    Rejected: ["Draft"],
  },
  Transaction: {
    "Draft": ["Submitted", "Sent"],
    "Submitted": ["Under Review", "Sent", "Partially Paid", "Paid", "Rejected"],
    "Sent": ["Checking", "Partially Paid", "Paid", "Rejected"],
    "Checking": ["Paid", "Rejected", "Under Review"],
    "Under Review": ["Approved", "Rejected"],
    "Approved": ["Partially Paid", "Paid", "Rejected"],
    "Partially Paid": ["Paid", "Rejected"],
    "Rejected": ["Draft"]
  },
  "Transaction Template": {
    Draft: ["Submitted"],
    Submitted: ["Approved", "Rejected"],
    Approved: ["Draft"],
    Rejected: ["Draft"],
  },
  "Financial Certificate": {
    Draft: ["Submitted"],
    Submitted: ["Approved", "Rejected"],
    Approved: ["Draft"],
    Rejected: ["Draft"],
  },
  "Booking Transactions": {
    "Draft": ["Submitted", "Sent", "Paid"],
    "Submitted": ["Under Review", "Sent", "Partially Paid", "Paid", "Rejected"],
    "Sent": ["Checking", "Partially Paid", "Paid", "Rejected"],
    "Checking": ["Paid", "Rejected", "Under Review"],
    "Under Review": ["Approved", "Rejected"],
    "Approved": ["Partially Paid", "Paid", "Rejected"],
    "Partially Paid": ["Paid", "Rejected"],
    "Rejected": ["Draft"]
  },
  DemandDeed: {
    Draft: ["Sent", "Checking"],
    Sent: ["Checking"],
    Checking: ["Paid", "Cancelled"],
    Cancelled: ["Draft"],
    Paid: [],
  },
  "Debit/Credit Note": {
    Draft: ["Submitted"],
    Submitted: ["Under Review", "Rejected"],
    "Under Review": ["Approved", "Rejected"],
    Approved: ["Draft"],
    Rejected: ["Draft"],
  },
  HRM: {
    Draft: ["Submitted"],
    Submitted: ["Under Review", "Rejected"],
    "Under Review": ["Approved", "Rejected"],
    Approved: ["Draft"],
    Rejected: ["Draft"],
  },
  MultiFamilySalesBooking: {
    Draft: ["Draft", "Checking", "In Progress", "Paid", "Cancelled"],
    Checking: ["Confirmed", "In Progress", "Paid", "Cancelled"],
    Confirmed: ["In Progress", "Paid", "Cancelled"],
    "In Progress": ["Paid", "Cancelled"],
    Cancelled: ["Draft"],
    Paid: [], // fully paid
  },
  "Site Observation": {
    Draft: ["In Review", "Issue Detected", "Archived"],
    "In Review": ["Issue Detected", "Archived"],
    "Issue Detected": ["Issue Fixing", "Archived"],
    "Issue Fixing": ["Archived"],
    Archived: ["Draft"],
  },
};
export { allStatusOptions };
