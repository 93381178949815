import React, { useState, useEffect } from 'react';
import BasicPrivateRoute from "../components/PrivateRoute/BasicPrivateRoute.js";
import SiteReport from "../components/sitereport/SiteReport.jsx";
import EditSiteReport from "../components/sitereport/EditSiteReport.jsx";
import ViewSiteReport from "../components/sitereport/ViewSiteReport.jsx";
import EditCustomerReport from "../components/sitereport/EditCustomerReport.jsx";

const Routes = () => {
    return [

    <BasicPrivateRoute
        exact
        path="/site-report/:profileId"
        noAppbar={true}
        useBothSide
        component={SiteReport}
      />,
      <BasicPrivateRoute
        exact
        path="/site-report/edit/:reportId"
        noAppbar={true}
        useBothSide
        component={EditSiteReport}
      />,
      <BasicPrivateRoute
        exact
        path="/customer-report/edit/:reportId"
        noAppbar={true}
        useBothSide
        component={EditCustomerReport}
      />,
      <BasicPrivateRoute
        exact
        path="/daily-progress/edit/:reportId"
        noAppbar={true}
        useBothSide
        component={EditSiteReport}
      />,
      <BasicPrivateRoute
        exact
        path="/site-report/view/:reportId"
        noAppbar={true}
        useBothSide
        component={ViewSiteReport}
      />,

    ];
  };
  
  export default Routes;
  
