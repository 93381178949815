import React, { useState, useEffect } from 'react';
import {
    Box,
    Drawer,
    Typography,
    Tabs,
    Tab,
    Card,
    CardContent,
    IconButton,
    Grid,
    Chip,
    Switch,
    Tooltip,
    Button
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core/styles';
import ReportSettingAddEdit from './ReportSettingAddEdit';
import { getReportSettingsApiCall } from './Api.call';

const useStyles = makeStyles((theme) => ({
    drawer: {
        '& .MuiDrawer-paper': {
            width: '100%',
            maxWidth: '600px',
            padding: '20px',
            // backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[5],
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
        borderBottom: `1px solid ${theme.palette.divider}`,
        paddingBottom: '10px',
    },
    card: {
        marginBottom: '16px',
        transition: 'transform 0.2s',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        },
        borderRadius: '8px',
        border: `1px solid ${theme.palette.divider}`,
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '16px',
    },
    scheduleInfo: {
        display: 'flex',
        gap: '8px',
        flexWrap: 'wrap',
        marginTop: '8px',
    },
    chip: {
        margin: '4px',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
    },
    actions: {
        display: 'flex',
        gap: '8px',
    },
    tabPanel: {
        padding: '20px 0',
        backgroundColor: theme.palette.background.paper,
    },
}));

function TabPanel({ children, value, index }) {
    const classes = useStyles();
    return (
        <div hidden={value !== index} className={classes.tabPanel}>
            {value === index && children}
        </div>
    );
}

const ReportSettingsDrawer = ({ open, onClose, onEdit, orgId, reportSettings = [], setReportSettings }) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const [editReportSetting, setEditReportSetting] = useState(null);
    const [reportSettingDrawerOpen, setReportSettingDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const activeSettings = reportSettings.filter(setting => setting.emailSchedule.enabled);
    const inactiveSettings = reportSettings.filter(setting => !setting.emailSchedule.enabled);


    
    const getAllReportSettings = async () => {
        try {
            setLoading(true);
            const data = await getReportSettingsApiCall({ orgId });
            if (data) {
                setReportSettings(data);
                console.log(data,' are the reports')
            }
        } catch (error) {
            console.error('Error fetching report settings:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCreateNew = () => {
        setEditReportSetting(null);
        setReportSettingDrawerOpen(true);
    };

    const handleEdit = (setting) => {
        setEditReportSetting(setting);
        setReportSettingDrawerOpen(true);
    };

    const handleAddEditClose = async () => {
        setReportSettingDrawerOpen(false);
        setEditReportSetting(null);
        await getAllReportSettings();
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const getFrequencyText = (schedule) => {
        switch (schedule.frequency) {
            case 'daily':
                return `Daily at ${schedule.time}`;
            case 'weekly':
                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                return `Weekly on ${days[schedule.dayOfWeek]} at ${schedule.time}`;
            case 'monthly':
                return `Monthly on day ${schedule.dayOfMonth} at ${schedule.time}`;
            default:
                return 'Not scheduled';
        }
    };

    const renderSettingCard = (setting) => (
        <Card className={classes.card} key={setting._id}>
            <CardContent>
                <Box className={classes.cardContent}>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            {setting.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Type: {setting.type}
                        </Typography>
                        
                        <Box className={classes.scheduleInfo}>
                            <Chip 
                                label={getFrequencyText(setting.emailSchedule)}
                                size="small"
                                className={classes.chip}
                            />
                            <Chip 
                                label={`Timezone: ${setting.emailSchedule.timeZone}`}
                                size="small"
                                className={classes.chip}
                            />
                        </Box>

                        <Box mt={1}>
                            <Typography variant="body2" color="textSecondary">
                                Projects: {setting.projectMaps.map(pm => pm.project.displayName).join(', ') || 'None'}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Recipients: {setting.userToEmailOverrides.length}
                            </Typography>
                        </Box>
                    </Box>

                    <Box className={classes.actions}>
                        <Tooltip title="Edit">
                            <IconButton 
                                size="small" 
                                onClick={() => handleEdit(setting)}
                                color="primary"
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton 
                                size="small" 
                                color="error"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            className={classes.drawer}
        >
            <Box className={classes.header}>
                <Typography variant="h5">Report Settings</Typography>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleCreateNew}
                    >
                        Create New
                    </Button>
                    <IconButton onClick={onClose}>
                        &times;
                    </IconButton>
                </Box>
            </Box>

            <Tabs 
                value={tabValue} 
                onChange={handleTabChange}
                variant="fullWidth"
            >
                <Tab 
                    label={`Active (${activeSettings.length})`} 
                    id="tab-0"
                />
                <Tab 
                    label={`Inactive (${inactiveSettings.length})`} 
                    id="tab-1"
                />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
                <Grid container spacing={2}>
                    {activeSettings.map(setting => (
                        <Grid item xs={12} key={setting._id}>
                            {renderSettingCard(setting)}
                        </Grid>
                    ))}
                    {activeSettings.length === 0 && (
                        <Typography variant="body1" color="textSecondary" style={{ padding: '20px' }}>
                            No active report settings found.
                        </Typography>
                    )}
                </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
                <Grid container spacing={2}>
                    {inactiveSettings.map(setting => (
                        <Grid item xs={12} key={setting._id}>
                            {renderSettingCard(setting)}
                        </Grid>
                    ))}
                    {inactiveSettings.length === 0 && (
                        <Typography variant="body1" color="textSecondary" style={{ padding: '20px' }}>
                            No inactive report settings found.
                        </Typography>
                    )}
                </Grid>
            </TabPanel>
            <ReportSettingAddEdit
                openDrawer={reportSettingDrawerOpen}
                setOpenDrawer={handleAddEditClose}
                editData={editReportSetting}
                setEditData={setEditReportSetting}
                getAllReportSettings={getAllReportSettings}
                orgId={orgId}
            />

        </Drawer>
    );
};

export default ReportSettingsDrawer;
