import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { Button, TextField, InputAdornment, ClickAwayListener, Grid } from "@material-ui/core";
import NormalDrawer from "../NormalDrawer";
import TuneIcon from '@material-ui/icons/Tune';
import { getPaginatedOptimizedTasks } from "./api.call";
import { useDebounce } from "react-use";
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import TaskCard from "./TaskCard";
import CustomSelect2 from "../CustomSelect2"
import { getLinerStatuses } from "../../../../helpers/approval";
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    drawerContent: {
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 155px)",
        padding: "20px",
        overflowY: "auto"
    },
    searchHeader: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    bottomCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #d2d1d1",
    },
    filterCont: {
        display: "block",
        position: "absolute",
        width: "calc(100% - 50px)",
        top: "105px",
        left: "25px",
        padding: "5px 15px 15px",
        backgroundColor: "white",
        borderRadius: "5px",
        zIndex: "999",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        transition: "all 0.3s",
    },
    hideFilterCont: {
        display: "none",
        position: "absolute",
        width: "calc(100% - 25px)",
        top: "105px",
        left: "25px",
        padding: "5px 15px 15px",
        backgroundColor: "white",
        borderRadius: "5px",
        zIndex: "999",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        transition: "all 0.3s",
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid #d2d1d1",
    },
    filterInputCont: {
        marginTop: "10px",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
        }
    }
}));

const TaskSelectDrawer = ({
    profileId, openTaskSelect, setOpenTaskSelect,
    linkedTasks, onSave
}) => {
    const classes = useStyles();

    const { teamDictionary, teamIds } = useSelector((state) => state.team);

    const [filterObj, setFilterObj] = useState({
        searchQuery: "",
        ancestors: [],
        target: [],
        level: null,
        statuses: []
    })
    const [loader, setLoader] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)
    const [totalPages, setTotalPages] = useState(1)
    const [options, setOptions] = useState([])
    const [changeState, setChangeState] = useState(false)
    const [curSelectedTasks, setCurSelectedTasks] = useState([])
    const [projectId, setProjectId] = useState(null)

    useEffect(() => {
        if (openTaskSelect) {
            setCurSelectedTasks(linkedTasks)
        }
    }, [linkedTasks, openTaskSelect])

    const getTasks = async () => {
        try {
            let locProjectId = null;
            teamIds && teamIds.length > 0 && teamIds.map((teamId) => {
                const teamData = teamDictionary[teamId];
                if (teamData?.parent?.profile?._id == profileId) {
                    locProjectId = teamData?.parent?._id
                } else if (teamData?.parent?.profile == profileId) {
                    locProjectId = teamData?.parent?._id
                }
            })
            setProjectId(locProjectId)
            setLoader(true)
            await getPaginatedOptimizedTasks({
                projectId: locProjectId,
                profileId: profileId,
                filterObj,
                curPage: curPage - 1,
                limit: pageLimit,
            })
                .then((data) => {
                    setOptions(data?.data || [])
                    let locTotalPage = Math.ceil((data?.count || 0) / pageLimit);
                    setTotalPages(locTotalPage)
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setLoader(false)
                })
        } catch (err) {
            console.log(err)
        }
    }

    useDebounce(() => {
        if (profileId && openTaskSelect) {
            getTasks()
        }
    }, 1000, [curPage, changeState])

    useEffect(() => {
        setCurPage(1)
        setChangeState(!changeState)
    }, [filterObj, profileId, openTaskSelect])

    return (
        <NormalDrawer
            openDrawer={openTaskSelect}
            setOpenDrawer={setOpenTaskSelect}
            anchor={"right"}
            width={"800px"}
            title={"Select Task(s)"}
            content={<div className={classes.drawerContent}>
                <div style={{ width: "100%", height: "5px" }} >
                    {loader && (<LinearProgress />)}
                </div>
                <div className={classes.searchHeader} >
                    <ClickAwayListener
                        onClickAway={() => {
                            setShowFilter(false)
                        }}
                    >
                        <div style={{ width: "95%" }} >
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Search..."
                                value={filterObj.searchQuery}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    setFilterObj({
                                        ...filterObj,
                                        searchQuery: e.target.value
                                    })
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setShowFilter(!showFilter)
                                            }}
                                        >
                                            <TuneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <div className={showFilter ? classes.filterCont : classes.hideFilterCont} >
                                <Grid container spacing={2} >
                                    <Grid item xs={12} className={classes.filterInputCont} >
                                        <p>Task(s)</p>
                                        <CustomSelect2
                                            selectedOption={filterObj.ancestors}
                                            onChange={(value) => {
                                                setFilterObj({
                                                    ...filterObj,
                                                    ancestors: value
                                                })
                                            }}
                                            additionalFilter={{
                                                projectId,
                                                profileId
                                            }}
                                            limit={10}
                                            apiUrl={`wbs/get/tasks/paginated`}
                                            uniqueField={"_id"}
                                            labelField={"taskName"}
                                            randerLabal={(data) => {
                                                return `${data?.mspId} ${data?.taskName}(${data.ancestors ? data.ancestors.length : 0})`
                                            }}
                                            isMulti={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.filterInputCont} >
                                        <p>Target(s)</p>
                                        <CustomSelect2
                                            selectedOption={filterObj.target}
                                            onChange={(value) => {
                                                setFilterObj({
                                                    ...filterObj,
                                                    target: value
                                                })
                                            }}
                                            additionalFilter={{
                                                projectId,
                                                profileId
                                            }}
                                            limit={10}
                                            apiUrl={`wbs/get/target/paginated`}
                                            uniqueField={"_id"}
                                            labelField={"title"}
                                            randerLabal={(data) => {
                                                return `${data?.title}`
                                            }}
                                            isMulti={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6} className={classes.filterInputCont} >
                                        <p>Level</p>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            type="number"
                                            placeholder="Enter level"
                                            value={filterObj.level}
                                            fullWidth
                                            onChange={(e) => {
                                                setFilterObj({
                                                    ...filterObj,
                                                    level: e.target.value
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} className={classes.filterInputCont} >
                                        <p>Status</p>
                                        <Autocomplete
                                            options={getLinerStatuses("WBS")}
                                            value={filterObj?.statuses}
                                            onChange={(event, value) => {
                                                setFilterObj({
                                                    ...filterObj,
                                                    statuses: value
                                                })
                                            }}
                                            fullWidth
                                            size="small"
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    variant="outlined"
                                                    {...params}
                                                    placeholder={"Select status"}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </ClickAwayListener>
                </div>
                <div className={classes.bodyCont} >
                    <Grid container spacing={2}>
                        {options.map((cardData) => (
                            <Grid item xs={6} key={cardData?._id} >
                                <TaskCard
                                    cardData={cardData}
                                    setLinkedTasks={setCurSelectedTasks}
                                    linkedTasks={curSelectedTasks}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
                <div className={classes.paginationCont} >
                    <Pagination
                        count={totalPages}
                        page={curPage}
                        color="primary"
                        siblingCount={0}
                        onChange={(event, value) => { setCurPage(value) }}
                    />
                </div>
                <div className={classes.bottomCont} >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onSave(curSelectedTasks)
                        }}
                        size="small"
                    >
                        Save & Close
                    </Button>
                </div>
            </div>}
        />
    );
};

export default TaskSelectDrawer;
