import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import HorizBox from "../styled/generic/HorizBox";
import FormBox from "../styled/generic/FormBox";
import TextField from "../styled/generic/TextField";
import ProjectPicker from "../styled/generic/ProjectPicker";
import ProjectBlockPickerDropdown from "../styled/generic/ProjectBlockPickerDropdown";
import DuoButtonGroup from "../styled/generic/DuoButtonGroup";
import FormHeaderWithActions from "../styled/generic/FormHeaderWithActions";
import { Add, ArrowBack } from "@mui/icons-material";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import Api from "../../helpers/Api";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import SlabRow  from "./slabRow.jsx";

const ScheduleEdit = ({ libraryId, scheduleId, onBack }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [project, setProject] = useState(null);
  const [selectedProjectBlock, setSelectedProjectBlock] = useState(null);
  const [slabs, setSlabs] = useState([]);

  // const [scheduleId, setScheduleId] = useState("");
  const [schedule, setSchedule] = useState([]);
  const [change, setChange] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  console.log(scheduleId,' in ScheduleEdit')
  // Get scheduleId from URL query parameters
  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const _scheduleId = searchParams.get("scheduleId");
  //   setScheduleId(_scheduleId);
  // }, [location.search]);

  const totalCumulativePercentage = slabs.reduce(
    (accumulator, currentValue) => {
      return accumulator + parseInt(currentValue.paymentPercentage);
    },
    0
  );

  const getSchedule = async () => {
    console.log('getschedule called with ',scheduleId)
    try {
      if (!scheduleId) {
        return;
      }
      setPageLoading(true);
      const { data } = await Api.post("/listing/booking/schedule/get-by-id", {
        scheduleId: scheduleId,
      });
      if (data) {
        setTitle(data?.title);
        setProject(data?.project);
        setSelectedProjectBlock(data?.projectBlock || null);
        setSchedule(data);
        setSlabs(data?.slabs || []);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
    setPageLoading(false);
  };

  useEffect(() => {
    getSchedule();
  }, [scheduleId]);

  const saveSchedule = async () => {
    if (totalCumulativePercentage > 100) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "The total Cumulative percentage should be equal to 100",
        },
      });
      return;
    }
    try {
      setLoading(true);
      const { data } = await Api.post("/listing/booking/schedule/update", {
        updateBody: {
          title,
          project,
          projectBlock: selectedProjectBlock,
        },
        slabs: slabs,
        _id: scheduleId,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Schedule updated successfully",
          },
        });
        onBack()
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setChange((prev) => !prev);
  }, [slabs]);

  if (pageLoading) {
    return (
      <Box
        sx={{
          height: "85vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <IconButton onClick={onBack}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5">
          Edit Schedule
        </Typography>
      </Box>

      <HorizBox>
        <FormBox label="Title">
          <TextField
            placeholder="Enter"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth={true}
          />
        </FormBox>
        <FormBox label="Project">
          <ProjectPicker
            selectedProject={project}
            setSelectedProject={setProject}
            hideLabel={true}
            fullWidth={true}
          />
        </FormBox>
        <FormBox label="Project Block">
          <ProjectBlockPickerDropdown
            selectedProjectBlock={selectedProjectBlock}
            setSelectedProjectBlock={setSelectedProjectBlock}
            hideLabel={true}
            projectId={project ? project._id : ""}
            fullWidth={true}
          />
        </FormBox>
      </HorizBox>

      <FormHeaderWithActions
        label="Slabs"
        labelTypographyVariant="h5"
        actions={
          <IconButton
            disabled={totalCumulativePercentage >= 100}
            onClick={() =>
              setSlabs([
                ...slabs,
                {
                  title: "",
                  description: "",
                  dueDate: new dayjs(),
                  paymentPercentage: 0,
                  cumulativePercentage: 0,
                  type: "milestone",
                  isNew: true,
                },
              ])
            }
          >
            <Add />
          </IconButton>
        }
      >
        {slabs.map((slab, index) => (
          <SlabRow
            key={index}
            slabs={slabs}
            setSlabs={setSlabs}
            slab={slab}
            index={index}
            change={change}
          />
        ))}
      </FormHeaderWithActions>

      <DuoButtonGroup
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        primaryButtonListener={saveSchedule}
        loadingPrimary={loading}
        secondaryButtonListener={onBack}
      />
    </Box>
  );
};

export default ScheduleEdit;
