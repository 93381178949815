import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import firebase from "firebase";
import configureStore, { history } from "./store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import App from "./App";
// import { CookiesProvider } from 'react-cookie';

import config from "./config/index";


import * as serviceWorker from "./serviceWorker";

const { store, persistor } = configureStore();

firebase.initializeApp(config.firebaseConfig);

// const { store, persistor } = configureStore;



ReactDOM.render(
  <Provider store={store}>
    <Router>
      <PersistGate loading={<div>loading</div>} persistor={persistor}>
        <App history={history} />
      </PersistGate>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
